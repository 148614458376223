<template>
  <div
      class="block lg:hidden fixed z-50 w-full h-16 -translate-x-1/2 bg-white border-t border-gray-200 bottom-0 left-1/2">
    <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
      <template v-for="menuItem in MENU_ITEMS">
        <nuxt-link v-if="menuItem.key === 'create-post'" :to="menuItem.href" class="flex items-center justify-center">
          <div
              :class="$route.path === menuItem.href ? 'bg-indigo-600' : 'bg-gray-500'"
              class="inline-flex items-center justify-center w-10 h-10 font-medium rounded-full group">
            <icon :name="menuItem.icon" class="text-white"/>
          </div>
        </nuxt-link>

        <nuxt-link v-else
                   :to="menuItem.href"
                   class="inline-flex flex-col items-center justify-center px-5 group text-center text-xs">
          <icon :class="$route.path === menuItem.href ? 'text-indigo-600' : 'text-gray-500'" :name="menuItem.icon"
                class="mb-1"/>

          <span class="mb-1">
            {{ menuItem.label }}
          </span>
        </nuxt-link>
      </template>
    </div>
  </div>
</template>

<script setup>
import {CALENDAR, CONNECT, CREATE_POST, POSTS, SETTINGS,} from '~/helpers/navigation-items.js'

const MENU_ITEMS = ref([POSTS, CALENDAR, CREATE_POST, CONNECT, SETTINGS])
</script>

<template>
  <button class="flex items-center space-x-2 hover:bg-gray-50" @click="logout">
    <icon name="material-symbols:logout" size="18"/>

    <span>
      Logout
    </span>
  </button>
</template>

<script setup>
import {authStore} from "~/stores/auth.js"

function logout() {
  authStore().logout()
}
</script>
<template>
  <div v-click-outside="hideDropdownMenu" class="relative">
    <button class="flex items-start" @click="showDropdownMenu = !showDropdownMenu">
      <div class="group flex items-center whitespace-pre-wrap break-words">
        <img class="h-8 w-8 rounded-full flex-shrink-0 mt-1"
             src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80">

        <div class="ml-3 text-left flex-grow">
          <p class="text-xs font-medium text-gray-700 group-hover:text-gray-900">
            {{ user.name }}
          </p>

          <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">
            {{ user.active_workspace.timezone }}{{ currentTime ? ` - ${currentTime}` : '' }}
          </p>
        </div>
      </div>
    </button>

    <div
        v-if="showDropdownMenu"
        class="absolute right-0 z-50 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none select-none text-gray-700 text-sm">
      <template v-for="(dropdown_menu_item, index) in DROPDOWN_MENU_ITEMS">
        <div v-if="index === 0 || dropdown_menu_item.group !== DROPDOWN_MENU_ITEMS[index - 1].group">
          <p class="px-5 text-xs text-gray-500 mt-2 uppercase tracking-widest">
            {{ dropdown_menu_item.group }}
          </p>
        </div>

        <nuxt-link
            :class="{
            'bg-gray-50': $route.path === dropdown_menu_item.href,
            'text-indigo-600 font-bold': dropdown_menu_item.label === 'Billing',
          }"
            :to="dropdown_menu_item.href"
            class="flex items-center space-x-2 px-5 py-2.5 hover:bg-gray-50">
          <icon v-if="dropdown_menu_item.icon" :name="dropdown_menu_item.icon" size="18"/>

          <span>
            {{ dropdown_menu_item.label }}
          </span>
        </nuxt-link>

        <t-divider-horizontal
            v-if="index < DROPDOWN_MENU_ITEMS.length - 1 && dropdown_menu_item.group !== DROPDOWN_MENU_ITEMS[index + 1].group"/>
      </template>

      <t-divider-horizontal/>

      <actions-logout class="px-5 py-3"/>
    </div>
  </div>
</template>

<script setup>
import {authStore} from '~/stores/auth'
import {ACCOUNT, BILLING, BROWSER_EXTENSION, MOBILE_APP, SECURITY, WORKSPACE} from '~/helpers/navigation-items.js'

const route = useRoute()

const user = computed(() => authStore().user)

const currentTime = ref(null)

const updateTime = () => {
  currentTime.value = new Date().toLocaleTimeString('en-US', {
    timeZone: user.value.active_workspace.timezone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: user.value.active_workspace.time_format == 12,
  })
}

onMounted(() => {
  updateTime()
  const timer = setInterval(updateTime, 1000)
  onUnmounted(() => clearInterval(timer))
})

const DROPDOWN_MENU_ITEMS = [WORKSPACE, ACCOUNT, SECURITY, BILLING, BROWSER_EXTENSION, MOBILE_APP]

const showDropdownMenu = ref(false)

function hideDropdownMenu() {
  showDropdownMenu.value = false
}

watch(() => route.path, () => hideDropdownMenu())
</script>

<template>
  <div v-click-outside="hideDropdownMenu" class="relative">
    <button class="flex items-center space-x-1.5 text-sm text-gray-500 cursor-pointer select-none"
            @click="showDropdownMenu = !showDropdownMenu">
      <span>
        Help
      </span>

      <icon name="material-symbols:help-outline-rounded" size="20"/>
    </button>

    <div
        v-if="showDropdownMenu"
        class="absolute right-0 z-50 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none select-none text-gray-700 text-sm">
      <template v-for="(dropdown_menu_item, index) in DROPDOWN_MENU_ITEMS">
        <div v-if="index === 0 || dropdown_menu_item.group !== DROPDOWN_MENU_ITEMS[index - 1].group">
          <p class="px-5 text-xs text-gray-500 my-2 uppercase tracking-widest">
            {{ dropdown_menu_item.group }}
          </p>
        </div>

        <a
            :class="{
            'bg-gray-50': $route.path === dropdown_menu_item.to,
            'text-indigo-600 font-bold': dropdown_menu_item.is_main,
          }"
            :href="dropdown_menu_item.to"
            class="flex items-center space-x-2 px-5 py-2 hover:bg-gray-50"
            target="_blank">
          <icon v-if="dropdown_menu_item.icon" :name="dropdown_menu_item.icon" size="18"/>

          <span>
            {{ dropdown_menu_item.label }}
          </span>
        </a>

        <t-divider-horizontal
            v-if="index < DROPDOWN_MENU_ITEMS.length - 1 && dropdown_menu_item.group !== DROPDOWN_MENU_ITEMS[index + 1].group"/>
      </template>
    </div>
  </div>
</template>

<script setup>

const route = useRoute()

const DROPDOWN_MENU_ITEMS = [
  {
    label: 'Learn',
    icon: 'material-symbols:video-library-outline-rounded',
    to: 'https://www.youtube.com/@ScreenCastsDev',
    group: 'resources',
  },

  {
    label: 'What\'s new?',
    icon: 'material-symbols:info-outline-rounded',
    to: 'https://blog.thelisting.app/whats-new',
    group: 'resources',
  },

  {
    label: 'Blog',
    icon: 'material-symbols:article-outline-rounded',
    to: 'https://blog.thelisting.app',
    group: 'resources',
  },

  {
    label: 'Roadmap',
    icon: 'material-symbols:map-outline-rounded',
    to: 'https://roadmap.thelisting.app',
    group: 'feedback',
  },

  {
    label: 'Suggest an idea',
    icon: 'material-symbols:lightbulb-outline-rounded',
    to: 'https://roadmap.thelisting.app/1-your-ideas-our-work/tabs/1-under-consideration/submit-idea',
    group: 'feedback',
  },

  {
    label: 'Help center',
    icon: 'material-symbols:help-outline-rounded',
    to: 'https://help.thelisting.app',
    group: 'problems & issues',
  },

  {
    label: 'Report a problem',
    icon: 'material-symbols:bug-report-outline-rounded',
    to: 'https://help.thelisting.app/report-a-problem',
    group: 'problems & issues',
  },
]

const showDropdownMenu = ref(false)

function hideDropdownMenu() {
  showDropdownMenu.value = false
}

watch(() => route.path, () => hideDropdownMenu())
</script>
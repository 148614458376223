<template>
  <div class="bg-white -mt-12 px-4 lg:pl-8 py-4 sticky top-16 mb-12" style="z-index: 1">
    <div class="sm:hidden">
      <select class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              @change="navigateTo($event.target.value)">
        <option
            v-for="navigation_item in NAVIGATION_ITEMS"
            :selected="navigation_item.url === $route.path"
            :value="navigation_item.url">
          {{ navigation_item.label }}
        </option>
      </select>
    </div>

    <div class="hidden sm:block">
      <nav class="flex space-x-4">
        <nuxt-link v-for="navigation_item in NAVIGATION_ITEMS"
                   :class="navigation_item.url === $route.path ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700'"
                   :to="navigation_item.url"
                   class="rounded-md px-3 py-2 text-sm font-medium">
          {{ navigation_item.label }}
        </nuxt-link>
      </nav>
    </div>
  </div>
</template>

<script setup>
const NAVIGATION_ITEMS = [
  {
    url: '/settings/workspace',
    label: 'Workspace',
  },

  {
    url: '/settings/account',
    label: 'Account',
  },

  {
    url: '/settings/security',
    label: 'Security',
  },

  /*{
    url: '/settings/notifications',
    label: 'Notifications',
  },*/

  {
    url: '/settings/billing',
    label: 'Billing',
  },
]
</script>
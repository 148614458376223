<template>
  <div class="hidden">
    <div class="btn-primary-xs btn-primary-disabled-xs"></div>
    <div class="btn-primary-sm btn-primary-disabled-sm"></div>
    <div class="btn-primary-md btn-primary-disabled-md"></div>
    <div class="btn-primary-lg btn-primary-disabled-lg"></div>
    <div class="btn-secondary-xs btn-secondary-disabled-xs"></div>
    <div class="btn-secondary-sm btn-secondary-disabled-sm"></div>
    <div class="btn-secondary-md btn-secondary-disabled-md"></div>
    <div class="btn-secondary-lg btn-secondary-disabled-lg"></div>
    <div class="btn-warning-xs btn-warning-disabled-xs"></div>
    <div class="btn-warning-sm btn-warning-disabled-sm"></div>
    <div class="btn-warning-md btn-warning-disabled-md"></div>
    <div class="btn-warning-lg btn-warning-disabled-lg"></div>
    <div class="btn-danger-xs btn-danger-disabled-xs"></div>
    <div class="btn-danger-sm btn-danger-disabled-sm"></div>
    <div class="btn-danger-md btn-danger-disabled-md"></div>
    <div class="btn-danger-lg btn-danger-disabled-lg"></div>
    <div class="btn-info-xs btn-info-disabled-xs"></div>
    <div class="btn-info-sm btn-info-disabled-sm"></div>
    <div class="btn-info-md btn-info-disabled-md"></div>
    <div class="btn-info-lg btn-info-disabled-lg"></div>
    <div class="btn-success-xs btn-success-disabled-xs"></div>
    <div class="btn-success-sm btn-success-disabled-sm"></div>
    <div class="btn-success-md btn-success-disabled-md"></div>
    <div class="btn-success-lg btn-success-disabled-lg"></div>
    <div class="btn-outline-primary-xs btn-outline-primary-disabled-xs"></div>
    <div class="btn-outline-primary-sm btn-outline-primary-disabled-sm"></div>
    <div class="btn-outline-primary-md btn-outline-primary-disabled-md"></div>
    <div class="btn-outline-primary-lg btn-outline-primary-disabled-lg"></div>
    <div class="btn-outline-secondary-xs btn-outline-secondary-disabled-xs"></div>
    <div class="btn-outline-secondary-sm btn-outline-secondary-disabled-sm"></div>
    <div class="btn-outline-secondary-md btn-outline-secondary-disabled-md"></div>
    <div class="btn-outline-secondary-lg btn-outline-secondary-disabled-lg"></div>
    <div class="btn-outline-warning-xs btn-outline-warning-disabled-xs"></div>
    <div class="btn-outline-warning-sm btn-outline-warning-disabled-sm"></div>
    <div class="btn-outline-warning-md btn-outline-warning-disabled-md"></div>
    <div class="btn-outline-warning-lg btn-outline-warning-disabled-lg"></div>
    <div class="btn-outline-danger-xs btn-outline-danger-disabled-xs"></div>
    <div class="btn-outline-danger-sm btn-outline-danger-disabled-sm"></div>
    <div class="btn-outline-danger-md btn-outline-danger-disabled-md"></div>
    <div class="btn-outline-danger-lg btn-outline-danger-disabled-lg"></div>
    <div class="btn-outline-info-xs btn-outline-info-disabled-xs"></div>
    <div class="btn-outline-info-sm btn-outline-info-disabled-sm"></div>
    <div class="btn-outline-info-md btn-outline-info-disabled-md"></div>
    <div class="btn-outline-info-lg btn-outline-info-disabled-lg"></div>
    <div class="btn-white-xs btn-white-disabled-xs"></div>
    <div class="btn-white-sm btn-white-disabled-sm"></div>
    <div class="btn-white-md btn-white-disabled-md"></div>
    <div class="btn-white-lg btn-white-disabled-lg"></div>
    <div class="btn-outline-white-xs btn-outline-white-disabled-xs"></div>
    <div class="btn-outline-white-sm btn-outline-white-disabled-sm"></div>
    <div class="btn-outline-white-md btn-outline-white-disabled-md"></div>
    <div class="btn-outline-white-lg btn-outline-white-disabled-lg"></div>
  </div>
  <NuxtPwaManifest/>
  <div>
    <navigation-menus-mobile-bottom-nav/>

    <navigation-menus-sidebar/>

    <div class="lg:pl-72">
      <navigation-menus-top-nav/>

      <main>
        <notification/>

        <div class="lg:mt-12 min-h-screen h-full">
          <navigation-settings v-if="$router.currentRoute.value.path.startsWith('/settings/')"/>

          <div class="lg:ml-8">
            <slot/>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import queryParams from '~/composables/queryParams.js'

const route = useRoute()
const router = useRouter()
const {$bus} = useNuxtApp()
const {t} = useI18n()

const workspaces = computed(() => authStore().user.workspaces)

onMounted(() => {
  document.addEventListener('keypress', (e) => {
    if (e.shiftKey && e.key === 'N' && !document.activeElement.matches('input, textarea, select')) {
      router.push('/create/custom')
    }
  })

  if (route.query.workspace_changed_to) {
    $bus.$emit('flash', {
      type: 'success',
      title: `Welcome to <b>${workspaces.value.find(workspace => workspace.id === route.query.workspace_changed_to).name}</b> workspace!`,
    })

    queryParams().removeQueryParam('workspace_changed_to')
  }

  if (route.query.error) {
    $bus.$emit('flash', {
      type: 'error',
      title: t(route.query.error),
    })

    queryParams().removeQueryParam('error')
  }
})
</script>

<style>
html {
  @apply bg-gray-100;
}
</style>

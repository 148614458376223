<template>
  <div v-click-outside="hideWorkspaceItems" class="relative">
    <div class="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm select-none cursor-pointer"
         @click="showWorkspaceItems = !showWorkspaceItems">
      <div class="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-1 text-white shadow-sm">
        <p class="text-xs font-bold whitespace-nowrap">
          {{ selectedWorkspace.name }}
        </p>
      </div>
      <div
          class="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50"
          type="button">
        <icon class="text-white" name="mdi:chevron-down" size="20"/>
      </div>
    </div>

    <ul v-if="showWorkspaceItems"
        class="absolute right-0 z-50 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
      <li v-for="workspace in workspaces"
          :class="workspace.id === selectedWorkspace.id ? 'bg-indigo-600 text-white' : 'text-gray-500 hover:bg-gray-50'"
          class="select-none text-sm text-gray-900 cursor-pointer"
          @click="changeActiveWorkspace(workspace)">
        <div class="flex flex-col p-4">
          <div class="flex justify-between">
            <p :class="{ 'font-semibold': workspace === selectedWorkspace }">
              {{ workspace.name }}
            </p>
          </div>
        </div>
      </li>

      <li class="select-none p-4 text-sm text-gray-900">
        <nuxt-link
            class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400"
            to="/workspaces/create">
          <icon class="mx-auto text-gray-400" :name="WORKSPACE.icon" size="40"/>

          <span class="mt-2 block text-gray-900">
              Create a new workspace
            </span>
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import {authStore} from "~/stores/auth.js"
import {userStore} from "~/stores/user.js"
import {WORKSPACE} from "~/helpers/navigation-items.js"

const route = useRoute()

const user = computed(() => authStore().user)
const workspaces = computed(() => user.value.workspaces)

const selectedWorkspace = computed(() => workspaces.value.find(workspace => workspace.id === user.value.active_workspace.id))

const showWorkspaceItems = ref(false)

function hideWorkspaceItems() {
  showWorkspaceItems.value = false
}

function changeActiveWorkspace(workspace) {
  userStore().changeActiveWorkspace(workspace)
}

watch(() => route.path, () => hideWorkspaceItems())
</script>
